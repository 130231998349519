import { useRef, useState, useEffect } from 'react';

function useScrollButtons(scrollDistance = 300) {
  const scrollContainerRef = useRef(null);
  const [isLeftButtonOn, setIsLeftButtonOn] = useState(false);
  const [isRightButtonOn, setIsRightButtonOn] = useState(true);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: -scrollDistance,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: scrollDistance,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setIsLeftButtonOn(scrollLeft > 0);
      setIsRightButtonOn(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll(); // Initialize button states on mount
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return { scrollContainerRef, isLeftButtonOn, isRightButtonOn, scrollLeft, scrollRight };
}

export default useScrollButtons;
