import Image from "next/image";
import { getStrapiMediaUrl } from "../../../utils";

const IMAGE_SIZE_MAP = {
  large: 1000,
  medium: 750,
  small: 500,
};

const getSrcSet = (formats) => {
  if (!Object.entries(formats).length) {
    return null;
  }

  const srcSetList = Object.entries(formats).map(([format, media]) => {
    return `${getStrapiMediaUrl(media.url)} ${IMAGE_SIZE_MAP[format]}w`;
  });

  return srcSetList.join(",");
};

const getStrapiMediaProps = ({ url, formats: allFormats, alternativeText }) => {
  if (!url) {
    return null;
  }
  const { thumbnail, ...formats } = allFormats || {};
  const srcSet = getSrcSet(formats);
  const src = getStrapiMediaUrl(
    formats?.large?.url || formats?.medium?.url || formats?.small?.url || url
  );

  return { srcSet, src, alt: alternativeText };
};

export const NextImage = ({ media, ...props }) => {
  if (!media) return null;
  const mediaProps = getStrapiMediaProps(media);
  if (!mediaProps) return null;

  const loader = ({ src, width }) => {
    return `${src}?w=${width}`;
  };


  return (
    <Image
      loader={loader}
      src={mediaProps.src}
      alt={mediaProps.alt || ""}
      srcSet={mediaProps.srcSet}
      width={props.width || 500}
      height={props.height || 500}
      {...props}
    />
  );
};

export default NextImage;